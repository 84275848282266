import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Homepage.css";
import edge from "./Homepage/Images/edge.png";

const Footer = () => {
  return (
    <div>
      <div className="footer-all">
        <div className="footer-des container mx-auto">
          <div className="row">
            <div className="col-md-4">
              <div className="f-logo">
                <img src={edge} alt="Edge" className="img-fluid" />
                <p>EDGE</p>
              </div>
              <p>
                Shop No. 203 <br /> II Floor, Hiltan Arcade Shopping Center,
                <br /> Evershine City <br /> Gokhivare, Vasai East, Vasai,
                Mumbai - 401208.
              </p>
              {/* <p></p> */}
              <a href="mailto:hello@edgepay.in" className="email-link">
                hello@edgepay.in
              </a>
            </div>
            <div className="col-md-2">
                <h3>Platform</h3>
                <ul className="platform">
                  <li>Analytics</li>
                  <li>Planning</li>
                  <li>Collaboration</li>
                  <li>Data Management</li>
                  <li>Integrations</li>
                  <li>Security</li>
                </ul>
  
            </div>
            <div className="col-md-2">
            <h3>Resources</h3>
                <ul className="platform">
                  <li>Customers</li>
                  <li>Strategic Finance</li>
                  <li>Ebooks & Guides</li>
                  <li>Webinars & Events </li>
                  <li>Podcast & Video</li>
                </ul>
            </div>
            <div className="col-md-2">
            <h3>Solutions</h3>
                <ul className="platform">
                  <li>Financial</li>
                  <li>Investors & CEOs</li>
                  <li>Revenue Operations</li>
                  <li>Sales & Marketing</li>
                  <li>Human Resources</li>
                </ul>
            </div>
            <div className="col-md-2">
            <h3>Resources</h3>
                <ul className="platform">
                  <li>Customers</li>
                  <li>Strategic Finance</li>
                  <li>Ebooks & Guides</li>
                  <li>Webinars & Events</li>
                  <li>Matrics Catalog</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-reserve">
        <p>©️ 2024 COPYRIGHT - Vaultwave Technologies Private limited </p>
      </div>
    </div>
  );
};

export default Footer;
